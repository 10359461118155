import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Widgets = lazy(() => import('./widgets/Widgets'));

const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
const Chats = lazy(() => import('./apps/Chats'));
const TodoList = lazy( () => import('./apps/TodoList'));

const Accordions = lazy(() => import('./basic-ui/Accordions')); 
const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Badges = lazy(() => import('./basic-ui/Badges'));
const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Modals = lazy(() => import('./basic-ui/Modals'));
const Progress = lazy(() => import('./basic-ui/Progress'));
const Paginations = lazy(() => import('./basic-ui/Paginations'));
const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
const Typography = lazy(() => import('./basic-ui/Typography'));
const Tooltips = lazy(() => import('./basic-ui/Tooltips'));
const Popups = lazy(() => import('./basic-ui/Popups'));

const Dragula = lazy(() => import('./advanced-ui/Dragula'));
const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
const Sliders = lazy(() => import('./advanced-ui/Sliders'));
const Carousel = lazy(() => import('./advanced-ui/Carousel'));
const Loaders = lazy(() => import('./advanced-ui/Loaders'));
const TreeView = lazy(() => import('./advanced-ui/TreeView'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
const Validation = lazy(() => import('./form-elements/Validation'));
const Wizard = lazy(() => import('./form-elements/Wizard'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const DataTable = lazy(() => import('./tables/DataTables'));
const ReactTable = lazy(() => import('./tables/ReactTable'));
const SortableTable = lazy(() => import('./tables/SortableTable'));

const VectorMap = lazy(() => import('./maps/VectorMap'));
const SimpleMap = lazy(() => import('./maps/SimpleMap'));

const Notifications = lazy(() => import('./notifications/Notifications'));

const Mdi = lazy(() => import('./icons/Mdi'));
const FlagIcons = lazy(() => import('./icons/FlagIcons'));
const FontAwesome = lazy(() => import('./icons/FontAwesome'));
const SimpleLine = lazy(() => import('./icons/SimpleLine'));
const Themify = lazy(() => import('./icons/Themify'));
const TypIcons = lazy(() => import('./icons/TypIcons'));

const TextEditors = lazy(() => import('./editors/TextEditors'));
const CodeEditor = lazy(() => import('./editors/CodeEditor'));

const ChartJs = lazy(() => import('./charts/ChartJs'));
const C3Charts = lazy(() => import('./charts/C3Charts'));
const Chartist = lazy(() => import('./charts/Chartist'));
const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
const GuageChart = lazy(() => import('./charts/GuageChart'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Login2 = lazy(() => import('./user-pages/Login2'));
const Register1 = lazy(() => import('./user-pages/Register'));
const Register2 = lazy(() => import('./user-pages/Register2'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const Profile = lazy(() => import('./general-pages/Profile'));
const Faq = lazy(() => import('./general-pages/Faq'));
const Faq2 = lazy(() => import('./general-pages/Faq2'));
const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
const Timeline = lazy(() => import('./general-pages/Timeline'));
const SearchResults = lazy(() => import('./general-pages/SearchResults'));
const Portfolio = lazy(() => import('./general-pages/Portfolio'));

const Invoice = lazy(() => import('./ecommerce/Invoice'));
const Pricing = lazy(() => import('./ecommerce/Pricing'));
const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
const ProjectList = lazy(() => import('./ecommerce/ProjectList'));
const Orders = lazy(() => import('./ecommerce/Orders'));

const Email = lazy(() => import('./apps/Email'));
const Calendar = lazy(() => import('./apps/Calendar'));
const Gallery = lazy(() => import('./apps/Gallery'));
const ServiceManagement = lazy(() => import('./service/service-management'));
const AddService = lazy(() => import('./service/AddService'));
const EditService = lazy(() => import('./service/EditService'));
const AddPackage = lazy(() => import('./package/AddPackage'));
const EditPackage = lazy(() => import('./package/EditPackage'));
const PackageManagement = lazy(() => import('./package/package-management'));
const RequestManagement = lazy(()=> import('./request/RequestManagement'))
const AddRequest = lazy(()=> import('./request/AddRequest'))
const EditRequest = lazy(()=> import('./request/EditRequest'))

const Attachment = lazy(()=> import('./request/Attachment'))
const AddAttachment = lazy(()=> import('./request/AddAttachment'))
const EditAttachment = lazy(()=> import('./request/EditAttachment'))

const VehicleModal = lazy(()=> import('./vehicle/VehicleModal'))
const AddVehicle = lazy(()=> import('./vehicle/AddVehicle'))
const VehicleMake = lazy(()=> import('./vehicle/VehicleMake'))
const AddVehicleMake = lazy(()=> import('./vehicle/AddVehicleMake'))
const EditVehicleMake = lazy(()=> import('./vehicle/EditVehicleMake'))
const VehicleYear = lazy(()=> import('./vehicle/VehicleYear'))
const AddVehicleYear = lazy(()=> import('./vehicle/AddVehicleYear'))
const EditVehicleModal = lazy(()=> import('./vehicle/EditVehicleModal'))
const EditVehicleYear = lazy(()=> import('./vehicle/EditVehicleYear'))
const Parts = lazy(()=> import('./vehicle/Parts'))
const AddParts = lazy(()=> import('./vehicle/AddParts'))
const EditParts = lazy(()=> import('./vehicle/EditParts'))
const Country= lazy(()=> import('./location/Country'))
const AddCountry= lazy(()=> import('./location/AddCountry'))
const EditCountry= lazy(()=> import('./location/EditCountry'))
const City= lazy(()=> import('./location/City'))
const AddCity= lazy(()=> import('./location/AddCity'))
const EditCity= lazy(()=> import('./location/EditCity'))
const Area= lazy(()=> import('./location/Area'))
const AddArea= lazy(()=> import('./location/AddArea'))
const EditArea= lazy(()=> import('./location/EditArea'))
const Slot= lazy(()=> import('./location/Slot'))
const AddSlot= lazy(()=> import('./location/AddSlot'))
const EditSlot= lazy(()=> import('./location/EditSlot'))

const WorkShop= lazy(()=> import('./location/WorkShop'))
const AddWorkShop= lazy(()=> import('./location/AddWorkShop'))
const EditWorkShop= lazy(()=> import('./location/EditWorkShop'))

const Van= lazy(()=> import('./vehicle/Van'))
const AddVan= lazy(()=> import('./vehicle/AddVan'))
const EditVan= lazy(()=> import('./vehicle/EditVan'))
const Department= lazy(()=> import('./staff/Department'))
const AddDepartment= lazy(()=> import('./staff/AddDepartment'))
const EditDepartment= lazy(()=> import('./staff/EditDepartment'))
const Designation= lazy(()=> import('./staff/Designation'))
const AddDesignation= lazy(()=> import('./staff/AddDesignation'))
const EditDesignation= lazy(()=> import('./staff/EditDesignation'))
const Staff= lazy(()=> import('./staff/Staff'))
const AddStaff= lazy(()=> import('./staff/AddStaff'))
const EditStaff= lazy(()=> import('./staff/EditStaff'))
const Customer= lazy(()=> import('./customer/Customer'))
const CustomerService= lazy(()=> import('./customer/CustomerServices'))
class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          {/* <Route exact path="/backoffice/dashboard" component={ Dashboard } /> */}
          <Route exact  path = "/request/request-management" component = {RequestManagement} />
          <Route  path = "/package/add-package" component = {AddPackage} />
          <Route  path = "/package/package-management" component = {PackageManagement} />
           <Route  path = "/service/service-management" component = {ServiceManagement} />
           <Route  path = "/service/add-service" component = {AddService} />
           <Route  path = "/service/edit-service/:id" component = {EditService} />
           <Route  path = "/package/edit-package/:id" component = {EditPackage} />
           <Route  path = "/request/request-management" component = {RequestManagement} />
           <Route  path = "/request/add-request" component = {AddRequest} />
           <Route  path = "/request/edit-request/:id" component = {EditRequest} />
           
           <Route  path = "/request/attachment" component = {Attachment} />
           <Route  path = "/request/add-attachment" component = {AddAttachment} />
           <Route  path = "/request/edit-attachment/:id" component = {EditAttachment} />

           <Route  path = "/vehicle/vehicle-modal" component = {VehicleModal} />
           <Route  path = "/vehicle/add-vehicle-modal" component = {AddVehicle} />
           <Route  path = "/vehicle/vehicle-make" component = {VehicleMake} />
           <Route  path = "/vehicle/add-vehicle-make" component = {AddVehicleMake} />
           <Route  path = "/vehicle/vehicle-year" component = {VehicleYear} />
           <Route  path = "/vehicle/add-vehicle-year" component = {AddVehicleYear} />
           <Route  path = "/vehicle/edit-vehicle-make/:id" component = {EditVehicleMake} />
           <Route  path = "/vehicle/edit-vehicle-modal/:id" component = {EditVehicleModal} />
           <Route  path = "/vehicle/edit-vehicle-year/:id" component = {EditVehicleYear} />
           <Route  path = "/parts/parts-management" component = {Parts} />
           <Route  path = "/parts/add-parts" component = {AddParts} />
           <Route  path = "/parts/edit-parts/:id" component = {EditParts} />
           <Route  path = "/van/van-management" component = {Van} />
           <Route  path = "/van/add-van" component = {AddVan} />
           <Route  path = "/van/edit-van/:id" component = {EditVan} />
           <Route  path = "/location/country" component = {Country} />
           <Route  path = "/location/add-country" component = {AddCountry} />
           <Route  path = "/location/edit-country/:id" component = {EditCountry} />
           <Route  path = "/location/city" component = {City} />
           <Route  path = "/location/add-city" component = {AddCity} />
           <Route  path = "/location/edit-city/:id" component = {EditCity} />
           <Route  path = "/location/area" component = {Area} />
           <Route  path = "/location/add-area" component = {AddArea} />
           <Route  path = "/location/edit-area/:id" component = {EditArea} />
           <Route  path = "/slot/slot-management" component = {Slot} />
           <Route  path = "/slot/add-slot" component = {AddSlot} />
           <Route  path = "/slot/edit-slot/:id" component = {EditSlot} />


           <Route  path = "/workshop/workshop-management" component = {WorkShop} />
           <Route  path = "/workshop/add-workshop" component = {AddWorkShop} />
           <Route  path = "/workshop/edit-workshop/:id" component = {EditWorkShop} />

           <Route  path = "/staff/department" component = {Department} />
           <Route  path = "/staff/add-department" component = {AddDepartment} />
           <Route  path = "/staff/edit-department/:id" component = {EditDepartment} />
           <Route  path = "/staff/designation" component = {Designation} />
           <Route  path = "/staff/add-designation" component = {AddDesignation} />
           <Route  path = "/staff/edit-designation/:id" component = {EditDesignation} />
           <Route  path = "/staff/staff-management" component = {Staff} />
           <Route  path = "/staff/add-staff" component = {AddStaff} />
           <Route  path = "/staff/edit-staff/:id" component = {EditStaff} />
           <Route  path = "/customers/customer-management" component = {Customer} />
           <Route  path = "/customers/customer-request" component = {CustomerService} />
          <Route exact path="/widgets" component={ Widgets } />
          <Route exact path="/apps/kanban-board" component={ KanbanBoard } />
          <Route exact path="/apps/todo-list" component={ TodoList } />
          <Route exact path="/apps/chats" component={ Chats } />

          <Route path="/basic-ui/accordions" component={ Accordions } />
          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/badges" component={ Badges } />
          <Route path="/basic-ui/breadcrumbs" component={ Breadcrumbs } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/modals" component={ Modals } />
          <Route path="/basic-ui/progressbar" component={ Progress } />
          <Route path="/basic-ui/pagination" component={ Paginations } />
          <Route path="/basic-ui/tabs" component={ TabsPage } />
          <Route path="/basic-ui/typography" component={ Typography } />
          <Route path="/basic-ui/tooltips" component={ Tooltips } />
          <Route path="/basic-ui/popups" component={ Popups } />

          <Route path="/advanced-ui/dragula" component={ Dragula } />
          <Route path="/advanced-ui/clipboard" component={ Clipboard } />
          <Route path="/advanced-ui/context-menu" component={ ContextMenu } />
          <Route path="/advanced-ui/sliders" component={ Sliders } />
          <Route path="/advanced-ui/carousel" component={ Carousel } />
          <Route path="/advanced-ui/loaders" component={ Loaders } />
          <Route path="/advanced-ui/tree-view" component={ TreeView } />

          <Route path="/form-Elements/basic-elements" component={ BasicElements } />
          <Route path="/form-Elements/advanced-elements" component={ AdvancedElements } />
          <Route path="/form-Elements/validation" component={ Validation } />
          <Route path="/form-Elements/wizard" component={ Wizard } />

          <Route path="/tables/basic-table" component={ BasicTable } />
          <Route path="/tables/data-table" component={ DataTable } />
          <Route path="/tables/react-table" component={ ReactTable } />
          <Route path="/tables/sortable-table" component={ SortableTable } />

          <Route path="/maps/vector-map" component={ VectorMap } />
          <Route path="/maps/simple-map" component={ SimpleMap } />
          
          <Route path="/notifications" component={ Notifications } />

          <Route path="/icons/mdi" component={ Mdi } />
          <Route path="/icons/flag-icons" component={ FlagIcons } />
          <Route path="/icons/font-awesome" component={ FontAwesome } />
          <Route path="/icons/simple-line" component={ SimpleLine } />
          <Route path="/icons/themify" component={ Themify } />
          <Route path="/icons/typicons" component={ TypIcons } />

          <Route path="/editors/text-editors" component={ TextEditors } />
          <Route path="/editors/code-editor" component={ CodeEditor } />
          
          <Route path="/icons/themify" component={ Themify } />

          <Route path="/charts/chart-js" component={ ChartJs } />
          <Route path="/charts/c3-chart" component={ C3Charts } />
          <Route path="/charts/chartist" component={ Chartist } />
          <Route path="/charts/google-charts" component={ GoogleCharts } />
          <Route path="/charts/sparkline-charts" component={ SparkLineCharts } />
          <Route path="/charts/guage-chart" component={ GuageChart } />


          <Route path="/login" component={ Login } />
          <Route path="/user-pages/login-2" component={ Login2 } />
          <Route path="/user-pages/register-1" component={ Register1 } />
          <Route path="/user-pages/register-2" component={ Register2 } />
          <Route path="/user-pages/lockscreen" component={ Lockscreen } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />

          <Route path="/general-pages/blank-page" component={ BlankPage } />
          <Route path="/general-pages/profile" component={ Profile } />
          <Route path="/general-pages/faq-1" component={ Faq } />
          <Route path="/general-pages/faq-2" component={ Faq2 } />
          <Route path="/general-pages/news-grid" component={ NewsGrid } />
          <Route path="/general-pages/timeline" component={ Timeline } />
          <Route path="/general-pages/search-results" component={ SearchResults } />
          <Route path="/general-pages/portfolio" component={ Portfolio } />

          <Route path="/ecommerce/invoice" component={ Invoice } />
          <Route path="/ecommerce/pricing" component={ Pricing } />
          <Route path="/ecommerce/product-catalogue" component={ ProductCatalogue } />
          <Route path="/ecommerce/project-list" component={ ProjectList } />
          <Route path="/ecommerce/orders" component={ Orders } />

          <Route path="/apps/email" component={ Email } />
          <Route path="/apps/calendar" component={ Calendar } />
          <Route path="/apps/gallery" component={ Gallery } />
        
     {
      localStorage?.getItem("token") ?
      <Redirect to="/request/request-management" />
      :
      <Redirect to="/login" />
     }
         
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;

